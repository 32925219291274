/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Billboard,
  SplitContent,
  VariableContent,
  Image,
  List,
  ListItem,
  LinkButton,
  LeshenPhoneCTA,
  AvailabilityForm,
  Accordion,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Stack, Typography, Dropdown } from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import StateCityCtaBar from '../components/StateCityCtaBar'

const StateTemplate = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const {
    Meta_Description,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
    FAQ_Group,
  } = data?.pageInfo?.edges?.[0]?.node

  const State_Name = getFullStateName(State)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
    },
  }

  const FaqQuestions = {
    'Group 1': [
      {
        title: `¿Está disponible Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Sí, los servicios de Internet de Brightspeed están disponibles en
              tu área, llámanos para ayudarte con la instalación de tu nueva
              conexión.
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cuánto cuesta el internet de Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Con planes de internet desde $50/mes, recibes una conexión a
              Internet rápida con datos ilimitados y sin contratos confusos.
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cuál es el número para contactar a Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Nos encanta hablar contigo, llámanos al{' '}
              <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a> para obtener
              las últimas ofertas en planes de internet, confirmar cobertura en
              tu zona, recibir asesoramiento y contratar un nuevo servicio.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 2': [
      {
        title: `¿Ofrece Brightspeed servicio en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Encontrar un servicio de Internet fuerte, confiable y económico en
              tu ciudad nunca fue tan fácil, llámanos para agendar la
              instalación de tu nuevo servicio.
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cuánto cuestan los paquetes de Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Obtén todos los beneficios de un servicio de Internet y teléfono
              residencial de Brightspeed desde $100/mes. Los servicios que
              necesita tu hogar a un gran precio.
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cuál es el número de Brightspeed en ${State_Name} para agregar un servicio?`,
        content: (
          <div>
            <Typography variant="feature">
              Agregar un servicio de Internet es super sencillo, solo llama al{' '}
              <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a> el número
              directo de Brightspeed y uno de nuestros asesores amablemente te
              guiará por el proceso.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 3': [
      {
        title: `¿Es Brightspeed un proveedor de internet en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Es casi seguro que Brightspeed pueda proporcionarte el servicio de
              internet en tu localidad, para confirmar la cobertura te invitamos
              a consultar el código postal de tu dirección en los buscadores de
              está página.
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cuál es el precio del internet de Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Los planes de internet de Brightspeed comienzan desde $50/mes con
              datos ilimitados, sin contratos ni cargos por terminación
              anticipada. (Se requiere facturación electrónica o prepago. Se
              pueden aplicar impuestos y cargos extras).
            </Typography>
          </div>
        ),
      },
      {
        title: `¿Cómo puedo contactar a Brightspeed en ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Contáctanos al{' '}
              <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a> en dónde uno
              de nuestros asesores te ayudará a encontrar las últimas ofertas,
              planes de internet, cobertura en tu zona y aclarará todas tus
              dudas.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
    ],
    'Group 4': [
      {
        title: `Is Brightspeed available in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, Brightspeed is available in {State_Name}?. For more
              information on locations we serve, check out our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink>.
            </Typography>
          </div>
        ),
      },
    ],
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            variant="full"
            backgroundColor="Primary"
            mainContent={
              <>
                <Typography variant="h1">
                  Servicios de Internet de Brighstspeed en {State_Name}.
                </Typography>
                <Typography>
                  Brightspeed ofrece servicios de internet para casa y oficina
                  con una red confiable en 20 estados de EE. UU.
                </Typography>
                <List style={{ marginBottom: '16px' }}>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Facturación sencilla</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Sin contratos anuales</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Expertos en servicio al cliente</Typography>
                    </Typography>
                  </ListItem>
                </List>

                <AvailabilityForm
                  label="Ingresa tu código postal"
                  buttonText="Verificar disponibilidad"
                  buttonColor="dark"
                  butttonBackgroundColor="black"
                />
              </>
            }
            image={
              <Image
                data={data.coupleOnCouch.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
          />
          <StateCityCtaBar spanish />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.motherAndSon.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Planes de internet de Brightspeed en {State_Name}.
                </Typography>
                <Typography>
                  {`Este es el plan de internet favorito en ${State_Name} cambiar La red de cable de Brightspeed es fuerte, confiable y económica.`}
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Datos ilimitados</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Sin cargos por terminación anticipada
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Conecta múltiples usuarios y dispositivos
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Transmite tu contenido favorito</Typography>
                  </ListItem>
                </List>
                <LinkButton to="/es/internet">Ver nuestro planes</LinkButton>
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.usmap.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Planes de internet en tu área {State_Name}.
                </Typography>
                <Typography>
                  Brightspeed ofrece paquetes de internet rápido para empresas y
                  hogares en 20 Estados de todo el país. La cobertura de zonas
                  se está expandiendo. Habla con uno de nuestros representantes
                  o ingresa tu código postal para conocer las ofertas
                  disponibles en tu zona.
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Selecciona tu Ciudad"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.womanLookingAtPhone.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Todo lo que siempre quisiste.
                </Typography>
                <Typography>
                  Sabemos que buscas paquetes de Internet que te brinden una
                  conectividad rápida y confiable a un precio razonable, con
                  términos de contrato flexibles y servicio al cliente sin
                  esperar por horas, ¡no busques más! Los planes de internet de
                  Brightspeed incluyen:
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Planes económicos y funcionales</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Facturación sin cargos ocultos</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Libertad para cancelar en cualquier momento
                    </Typography>
                  </ListItem>
                </List>
                <LinkButton to="tel:1-855-592-2004">
                  Llama ahora {rotatedNumber}
                </LinkButton>
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  Servicios de Internet cerca de mi.
                </Typography>
                <Typography>
                  Brightspeed ofrece paquetes de internet rápido para hogares y
                  negocios en 20 estados de todo el país. Los servicios de
                  Internet se está expandiendo, habla con uno de nuestros
                  asociados o ingresa tu código postal para conocer las últimas
                  ofertas disponibles en tu zona.
                </Typography>
              </>
            }
          >
            <AvailabilityForm
              label="Ingresa tu código postal"
              buttonText="Verificar disponibilidad"
              buttonColor="dark"
              butttonBackgroundColor="black"
            />
          </VariableContent>
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.manUsingLaptop.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  ¿Por qué debes elegir Brightspeed?
                </Typography>
                <Typography>
                  Al elegir un paquete de Internet de Brightspeed podrás
                  disfrutar de Internet confiable hasta la puerta de tu casa a
                  un precio súper accesible.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      Velocidades rápidas y confiables, para hacer justo lo que
                      necesitas hacer
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Un plan dentro de tu presupuesto</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Datos ilimitados sin cargos adicionales
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Sin contratos confusos</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Buen{' '}
                      <GatsbyLink to="/es/servicio-al-cliente">
                        servicio al cliente
                      </GatsbyLink>
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA to="tel:1-855-592-2004">
                  Llama al
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  Encuentra planes de Brightspeed cerca de mí.
                </Typography>
                <Typography>
                  Confirma si Brightspeed está disponible en tu dirección y
                  ordena un nuevo servicio hoy.
                </Typography>
                <AvailabilityForm
                  label="Ingresa tu código postal"
                  buttonText="Verificar disponibilidad"
                  buttonColor="dark"
                  butttonBackgroundColor="black"
                />
              </>
            }
          />

          <VariableContent
            mainContent={
              <Typography variant="h2">
                Preguntas frecuentes sobre la cobertura de Brightspeed.
              </Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={FaqQuestions[FAQ_Group]} />
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerBrightspeedSpanishAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          FAQ_Group
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "1916f206-3ce5-5e21-bee4-d7c73b69f342" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "2301f5f4-33f8-5661-8778-5c49dfd7e80f" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerBrightspeedAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    coupleOnCouch: contentfulMedia(
      contentful_id: { eq: "1i9L539ObGbUCZwWtVKspU" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    motherAndSon: contentfulMedia(
      contentful_id: { eq: "1ZPIicrvCgOpAXeM1VZTkL" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    usmap: contentfulMedia(contentful_id: { eq: "6UOumxWDGqjDpFD2IVSVd3" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    womanLookingAtPhone: contentfulMedia(
      contentful_id: { eq: "1PJZXc76lVVRFtGau8YmSE" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    manUsingLaptop: contentfulMedia(
      contentful_id: { eq: "4qWiTsupysMPzwa5DmcGCi" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phoneIcon: contentfulMedia(
      contentful_id: { eq: "75oquD8iz9yb6Tp9xcSX9T" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    customerIcon: contentfulMedia(
      contentful_id: { eq: "6bqqzUXtSCAXCvopFBTQY5" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    blogIcon: contentfulMedia(contentful_id: { eq: "4fvj1e0zb18tsnEMB0jVIm" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
  }
`
